<template>
  <div class="contact">
    <MainLayout>
      <template v-slot:content>
        <section>
          <h2>Kom in contact.</h2>

          <p>
            Benieuwd of we iets voor je kunnen betekenen? <br /><br />
            Neem contact op via
            <span class="pink">Leander@lyghtning.nl</span>
            <br /><br />
            KVK 75666421
          </p>
        </section>
      </template>
    </MainLayout>
  </div>
</template>


<script>
import MainLayout from "@/components/MainLayout.vue";

export default {
  name: "Contact",
  components: {
    MainLayout,
  },
};
</script>